import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import {
	ReportInfoPanel,
	AdjustedScore,
	ScoringDetailsCta,
} from 'components/mosaic';

import { CutoffsScoringDetailsModal } from '../../../../CutoffsScoringDetailsModal';

import {
	AnswerResponse,
	DelayedRecognitionPerformance,
} from '../DelayedRecognition.types';
import { DelayedRecognitionScore } from '../DelayedRecognitionScore';
import { DelayedRecognitionTable } from '../DelayedRecognitionTable';

import { CaptionFaded, Container } from './DelayedRecognitionLayout.style';

export type DelayedRecognitionLayoutProps = {
	performance: DelayedRecognitionPerformance;
	answers: AnswerResponse[];
};

export function DelayedRecognitionLayout({
	performance,
	answers,
}: Readonly<DelayedRecognitionLayoutProps>) {
	const [opened, { open: openModal, close: closeModal }] =
		useDisclosure(false);
	const { t } = useTranslation();

	return (
		<Container>
			<ReportInfoPanel
				title={t(
					'web.report.pvltDelayedRecognitionReport.delayedRecognition'
				)}
				tooltipText={
					t('web.report.pvltDelayedRecognitionReport.description', {
						returnObjects: true,
					}) as string[]
				}
			>
				<Stack gap={12}>
					<DelayedRecognitionScore performance={performance} />
					{performance.score.adjusted && <AdjustedScore />}
					{performance.cutoff?.value && (
						<ScoringDetailsCta onClick={openModal} />
					)}
				</Stack>
			</ReportInfoPanel>
			<Container>
				<DelayedRecognitionTable answers={answers} />
			</Container>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
			{performance.cutoff?.value && (
				<CutoffsScoringDetailsModal
					ageRange={performance.cutoff.ageRange}
					cutoff={performance.cutoff.value}
					open={opened}
					onClose={closeModal}
				/>
			)}
		</Container>
	);
}
