import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

export function AdjustedScore() {
	const { t } = useTranslation();

	return (
		<Flex align='center' gap={4}>
			<Icon
				color={theme.color.bodyTextSecondary}
				icon={icons.ActionRepeat}
			/>
			<Typography.P2 color={theme.color.bodyTextSecondary}>
				{t('web.report.common.adjustedByEducation')}
			</Typography.P2>
		</Flex>
	);
}
