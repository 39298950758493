import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { DelayedRecognitionPerformance } from './DelayedRecognition.types';
import { createDelayedRecognitionScore } from './createDelayedRecognitionScore';

export const parseDelayedRecognitionMetrics = (
	segmentResult: DeepSegmentResult
) => {
	const performance: DelayedRecognitionPerformance = {
		impaired: -1,
		percentile: null,
		score: {
			adjusted: false,
			value: '',
		},
	};

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recognition_score')) {
			performance.score.value = isNaN(Number(metricItem.value))
				? null
				: createDelayedRecognitionScore(metricItem.value);
		}

		if (metricItem.key === 'pvlt6_age_range') {
			performance.cutoff = {};
			performance.cutoff.ageRange = metricItem.value;
		}

		if (metricItem.key === 'pvlt6_impairment_cutoff') {
			if (!performance.cutoff) {
				performance.cutoff = {};
			}
			performance.cutoff.value = metricItem.value;
		}

		if (metricItem.key === 'pvlt6_impairment_classification') {
			if (metricItem.value === 'IMPAIRED') {
				performance.impaired = 1;
			} else {
				performance.impaired = 0;
			}
		}

		if (
			metricItem.key ===
			'pvlt6_impairment_threshold_adjusted_by_education'
		) {
			performance.score.adjusted = metricItem.value === 'true';
		}

		if (metricItem.key === 'pvlt6_percentile') {
			if (metricItem.value !== '-1') {
				const value = Number(metricItem.value);

				if (!isNaN(value)) {
					performance.percentile = value;
				}
			}
		}
	});

	return { performance };
};
