import {
	FeatureType,
	OperationToken,
} from '@lh/eng-platform-organization-service-rest-client';

import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { UserContext } from 'components/context';
import {
	Breakpoints,
	MediaQueries,
} from 'components/providers/StyleProvider/MediaQueries';
import { AnalyticsReportName } from 'components/report/BatteryComponents/BatteryHeader/BatteryHeader.analytics';
import {
	PDFDownloadLinkResponse,
	pollPromiseForPDFLink,
} from 'components/report/BatteryComponents/PdfDownloadService';
import { DcrScoringWidget } from 'components/report/CDS/DCR/DCRSummaryWidget/DCRSummaryWidget';
import { NoteTemplate } from 'components/report/NoteTemplate/NoteTemplate';
import { showNoteTemplate } from 'components/shared/DataTable/schemas/resultsSchema';
import { LineDivider } from 'components/shared/LineDivider';
import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { ButtonSm, LoadingButton } from 'components/shared/designSystem';
import {
	CDSVersions,
	useCDS,
	useGetBatteryResults,
} from 'components/shared/hooks/';
import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import useWindowDimensions from 'components/shared/hooks/useWindowDimensions';
import { Auth } from 'features/auth-service';
import {
	GetBatteryResultsForIndividualQuery,
	ReportType,
} from 'generated/graphql';
import { icons } from '../../../../../enums/icons';
import { LinusModal } from '../../../../shared/LinusModal';
import { LinusTooltip } from '../../../../shared/LinusTooltip';
import { StyledCard } from '../../../../shared/StyledCard';
import { StyledLinkButton } from '../../../../shared/StyledLinkButton';
import { Icon } from '../../../../shared/designSystem/Icon';
import { H4 } from '../../../../shared/designSystem/TextComponents';
import { FormattedSegmentResults } from '../../../cognitiveEvalParseData/cognitiveEvalParseData';
import { Metric } from '../../../segmentUtilities/metrics';
import { AdditionalDcrResults } from '../../AdditionalDcrResults';
import { DCTClassificationString } from '../MiniModalReport';

import {
	getDcrScores,
	MAX_ATTEMPTS,
	POLLING_INTERVAL,
	pollingSuccess,
	shouldShowAdditionalDcr,
} from './MiniModalOverview.utils';
import { MiniModalScoring } from './MiniModalScoring';
import { ScoringDetails } from './ScoringDetails';
import { ViewFollowUpButton } from './ViewFollowUpButton';

type MiniModalReportProps = {
	score: Metric;
	classification: string;
	miniModalSubscoreSegments: FormattedSegmentResults[];
	mmsePrediction?: Metric;
	ficPrediction?: Metric;
	memoryImpairmentProbability?: Metric;
};

type CustomBatteryResult =
	| GetBatteryResultsForIndividualQuery['participant']['batteryResults']['nodes'][0]
	| null;

export const MiniModalOverview = ({
	score,
	classification,
	miniModalSubscoreSegments,
	mmsePrediction,
	ficPrediction,
	memoryImpairmentProbability,
}: MiniModalReportProps): JSX.Element => {
	const cds = useCDS();

	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const features = useOrganizationFeatures();
	const windowDimension = useWindowDimensions();

	const [batteryResult, setBatteryResult] =
		useState<CustomBatteryResult>(null);
	const [showNoteTemplateModal, setShowNoteTemplateModal] = useState(false);
	const [showScoringDetailsModal, setShowScoringDetailsModal] =
		useState(false);

	const { participantId, reportId } = useParams() as {
		participantId: string;
		reportId: string;
	};

	const { data, loading } = useGetBatteryResults(participantId || '');

	const hasNoteTemplateFlag = features.includes(FeatureType.NoteTemplate);
	const hasMmse =
		features.includes(FeatureType.MmseEstimation) && mmsePrediction;
	const hasMip =
		features.includes(FeatureType.MemoryImpairmentProbability) &&
		memoryImpairmentProbability;
	const hasFic =
		features.includes(FeatureType.FunctionalImpairmentProbability) &&
		ficPrediction;

	const requiresReview =
		score?.value < 0 ||
		classification === DCTClassificationString.UNANALYZABLE;
	const isTablet = windowDimension.width <= Breakpoints.lg;

	const showAdditionalDcr = shouldShowAdditionalDcr(
		!!hasMmse,
		!!hasMip,
		!!hasFic,
		batteryResult?.battery.batteryType
	);

	const showNoteTemplateButton =
		hasNoteTemplateFlag &&
		!!batteryResult?.battery.batteryType &&
		showNoteTemplate(batteryResult?.battery.batteryType);
	const showCds = cds === CDSVersions.V2 && !requiresReview;
	const showScoresDivider = showAdditionalDcr && isTablet;

	const { delayedScore } = getDcrScores(miniModalSubscoreSegments);
	const dctFormattedScore = Number(score?.value) - delayedScore;
	const dcrData = {
		DCR: Number(score?.value),
		clockDrawing: dctFormattedScore,
		delayedRecall: delayedScore,
	};

	useEffect(() => {
		const batteryResult = data?.participant.batteryResults.nodes.find(
			(item) => item.id === reportId
		);

		if (batteryResult?.battery.batteryType) {
			setBatteryResult(batteryResult);
		}
	}, [data]);

	function handleScoringDetailsModal() {
		setShowScoringDetailsModal(!showScoringDetailsModal);
	}

	function handleScoringDetailsButton() {
		sendEventData({ eventType: AnalyticsAction.ClickedScoring });
		setShowScoringDetailsModal(true);
	}

	function handleViewFollowUpClick() {
		sendEventData({
			eventType: AnalyticsAction.ClickedFollowup,
			eventProperties: {
				source: 'Results',
			},
		});

		navigate(`/patients/${participantId}`, {
			state: { scrollToPathway: true },
		});
	}

	function handleNoteTemplateClose() {
		setShowNoteTemplateModal(false);
	}

	function handleNoteTemplateOpen() {
		sendEventData({
			eventType: AnalyticsAction.ClickedNote,
			eventProperties: {
				source: 'Web Report',
			},
		});
		setShowNoteTemplateModal(true);
	}

	return (
		<Wrapper>
			<Header>
				<HeaderContent
					batteryResult={batteryResult}
					showNoteTemplateButton={showNoteTemplateButton}
					showCds={showCds}
					onNoteTemplateClick={handleNoteTemplateOpen}
					onViewFollowUpClick={handleViewFollowUpClick}
				/>
				<LineDivider />
			</Header>
			<ScoringWrapper>
				<DcrScoringContainer>
					<DcrScoringWrapper>
						<DcrScoringWidget
							score={dcrData}
							additionalDcr={!!showAdditionalDcr}
						/>
						<StyledLinkButton
							style={{
								alignSelf: 'flex-start',
							}}
							role='link'
							onClick={handleScoringDetailsButton}
						>
							<Trans i18nKey='web.dcrReport.overview.scoringDetails.button.content' />
						</StyledLinkButton>
					</DcrScoringWrapper>
				</DcrScoringContainer>
				{showScoresDivider && <LineDivider />}
				{showAdditionalDcr && (
					<AdditionalDcrWrapper>
						<AdditionalDcrResults
							mmsePrediction={Number(mmsePrediction?.value)}
							ficScore={Number(ficPrediction?.value)}
							mipScore={Number(
								memoryImpairmentProbability?.value
							)}
						/>
					</AdditionalDcrWrapper>
				)}
			</ScoringWrapper>
			{showScoringDetailsModal && (
				<LinusModal
					title={t`web.dcrReport.overview.scoringDetails.modal.title`}
					onClose={handleScoringDetailsModal}
				>
					<ScoringDetails
						onClose={handleScoringDetailsModal}
						algorithmVersion={score?.version}
						rangesComponent={
							<MiniModalScoring version={score?.version} />
						}
					/>
				</LinusModal>
			)}
			{showNoteTemplateModal && (
				<NoteTemplate
					batteryDataLoading={loading}
					batteryData={data}
					participantId={participantId}
					batteryResultId={reportId}
					orgId={currentUser.organizationId}
					onClose={handleNoteTemplateClose}
				/>
			)}
		</Wrapper>
	);
};

type HeaderCountProps = {
	batteryResult?: CustomBatteryResult;
	showNoteTemplateButton: boolean | null;
	showCds: boolean;
	onNoteTemplateClick: () => void;
	onViewFollowUpClick: () => void;
};

export function HeaderContent({
	batteryResult,
	showNoteTemplateButton,
	showCds,
	onNoteTemplateClick,
	onViewFollowUpClick,
}: Readonly<HeaderCountProps>) {
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	async function handleGetPathwayData() {
		return await pollPromiseForPDFLink(Auth, {
			organizationId: currentUser.organizationId,
			batteryResultId: batteryResult?.id ?? '',
			reportType: ReportType.ClinicalPathwaysReport,
			language: currentUser.organizationDefaultUserLocale.display ?? '',
		});
	}

	function handlePollingSuccess(response: PDFDownloadLinkResponse) {
		sendEventData({
			eventType: AnalyticsAction.DownloadedReport,
			eventProperties: {
				reportName: AnalyticsReportName.CLINICAL_PATHWAYS,
			},
		});
		return pollingSuccess(response);
	}

	return (
		<HeaderContentContainer>
			<TitleContainer>
				<Title>
					<Trans i18nKey='web.dcrReport.overview.titleWithTrademark' />
				</Title>
				<LinusTooltip
					overlay={
						<Tooltip>{t`web.dcrReport.overview.tooltip`}</Tooltip>
					}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			</TitleContainer>
			<HeaderButtonsWrapper>
				{showCds && (
					<ViewFollowUpButton onClick={onViewFollowUpClick} />
				)}
				<ReportsButtonWrapper>
					{showNoteTemplateButton && (
						<ButtonSm
							IconLeft={icons.NoteClipboard}
							iconSize={18}
							text={t`web.report.noteTemplate.button`}
							onClick={onNoteTemplateClick}
						/>
					)}
					{showCds && (
						<ShowIfAuthorized
							operations={[OperationToken.CreateReport]}
						>
							<LoadingButton
								text={t`web.report.pathway.linusPathway`}
								interval={POLLING_INTERVAL}
								maxAttempts={MAX_ATTEMPTS}
								onGetData={handleGetPathwayData}
								onPollSuccess={handlePollingSuccess}
							/>
						</ShowIfAuthorized>
					)}
				</ReportsButtonWrapper>
			</HeaderButtonsWrapper>
		</HeaderContentContainer>
	);
}

const Wrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		height: auto;
		min-width: 500px;
		padding: ${spacing.xl};
	`
);

const HeaderButtonsWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex: 1 1 auto;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);

const ReportsButtonWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		margin-left: auto;
		gap: ${spacing.sm};
	`
);

const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

const HeaderContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.sm};
		flex-wrap: wrap;
	`
);

const TitleContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
	`
);

const Title = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		min-width: 287px;
	`
);

const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);

const ScoringWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.md};
		margin-top: ${spacing.md};

		@media ${MediaQueries.maxWidth.lg} {
			flex-direction: column;
		}
	`
);

const DcrScoringContainer = styled.div`
	@media ${MediaQueries.maxWidth.lg} {
		width: 100%;
	}
`;

const DcrScoringWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: inline-flex;
		flex-direction: column;
		gap: ${spacing.md};
		max-width: 625px;
		min-width: 445px;

		@media ${MediaQueries.maxWidth.lg} {
			max-width: unset;
			width: 100%;
		}
	`
);

const AdditionalDcrWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
		width: 100%;

		@media ${MediaQueries.maxWidth.lg} {
			margin-top: 0;
		}
	`
);
