import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { DelayedRecallLayout } from './DelayedRecallLayout';
import { useDelayedRecallData } from './useDelayedRecallData';

type DelayedRecallReportProps = {
	segmentResult: DeepSegmentResult;
};

export const DelayedRecallReport = ({
	segmentResult,
}: Readonly<DelayedRecallReportProps>) => {
	const data = useDelayedRecallData(segmentResult);

	return (
		<DelayedRecallLayout
			performance={data.performance}
			trial={data.trial}
		/>
	);
};
